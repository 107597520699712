import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout/page";
import Main from "./pages/main/page";
import Upload from "./pages/upload/page";
import { WagmiProvider } from "wagmi";
import { config } from "./config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export default function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <Routes>
            <Route exact path="/" element={<Layout />}>
              <Route index element={<Main />} />
              <Route path="/upload" element={<Upload />} />
            </Route>
          </Routes>
        </HashRouter>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
