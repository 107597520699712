import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import { IoMdSettings } from "react-icons/io";
import { useRef } from "react";
import { useState } from "react";
import { Menu } from "@mui/material";
import {
  Connector,
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
} from "wagmi";
import { normalize } from "viem/ens";
import makeBlockie from "ethereum-blockies-base64";

const Header = () => {
  let settingsRef = useRef(null);
  let connectRef = useRef(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [connectOpen, setConnectOpen] = useState(false);
  const { connectors, connect } = useConnect();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { data: ensName, isFetched: isNameResolved } = useEnsName({
    address: address,
  });
  const { data: ensAvatar, isFetched: isAvatarResolved } = useEnsAvatar({
    name: normalize(ensName),
  });

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.header}>
        <div className={styles.section}>
          <a href="/" passHref className={styles.home}>
            based.md
          </a>
        </div>
        <div className={styles.section}>
          <a
            href="/#/upload"
            passHref
            className={
              document.location.pathname === "/upload" ? styles.active : ""
            }
          >
            New Post
          </a>

          <div
            ref={settingsRef}
            onClick={() => setSettingsOpen(true)}
            className={styles.flex}
          >
            <IoMdSettings className={styles.settings} />
          </div>
        </div>
      </div>

      {/* Menu */}
      <Menu
        anchorEl={settingsRef.current}
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setConnectOpen(false);
        }}
        classes={{
          root: styles.menu,
          paper: styles.paper,
        }}
      >
        <div className={styles.container}>
          {address && (
            <>
              <div className={styles.section}>
                <div className={styles.avatar}>
                  {isAvatarResolved && ensAvatar ? (
                    <img
                      src={ensAvatar}
                      alt="avatar"
                      className={styles.pfp}
                      onError={(e) => {
                        e.target.src = makeBlockie(address);
                      }}
                    />
                  ) : (
                    <img
                      src={makeBlockie(address)}
                      alt="avatar"
                      className={styles.pfp}
                    />
                  )}
                  {isNameResolved && ensName ? (
                    <div className={styles.col}>
                      <b>{ensName}</b>
                      <span>
                        {address.slice(0, 10)}...{address.slice(-6)}
                      </span>
                    </div>
                  ) : (
                    <b>
                      {address.slice(0, 10)}...{address.slice(-6)}
                    </b>
                  )}
                </div>
              </div>
            </>
          )}
          <div className={`${styles.section} ${styles.action}`}>
            IPFS Settings
          </div>
          <div className={`${styles.section} ${styles.action}`}>
            RPC Settings
          </div>
          <div
            className={`${styles.section} ${styles.action}`}
            ref={connectRef}
            onClick={() => (address ? disconnect() : setConnectOpen(true))}
          >
            {address ? <>Disconnect Wallet</> : <>Connect Wallet</>}
          </div>
        </div>
      </Menu>

      {/* Connect Menu */}
      <Menu
        anchorEl={connectRef.current}
        open={settingsOpen && connectOpen && !address}
        onClose={() => setConnectOpen(false)}
        classes={{
          root: styles.menu,
          paper: styles.paper,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={styles.container}>
          {connectors.map((connector) => (
            <div
              className={`${styles.section} ${styles.action}`}
              key={connector.uid}
              onClick={() => {
                setConnectOpen(false);
                connect({ connector });
              }}
            >
              {connector.name}
            </div>
          ))}
        </div>
      </Menu>
    </div>
  );
};

export default Header;
