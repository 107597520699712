import { useState } from "react";
import "@mdxeditor/editor/style.css";
import {
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  codeBlockPlugin,
  linkPlugin,
  linkDialogPlugin,
  tablePlugin,
  codeMirrorPlugin,
  useCodeBlockEditorContext,
  sandpackPlugin,
  InsertTable,
  toolbarPlugin,
  toolbarContents$,
  UndoRedo,
  BoldItalicUnderlineToggles,
  InsertImage,
} from "@mdxeditor/editor";
import moment from "moment";
import { useRef } from "react";
import { useEffect } from "react";
import styles from "./styles.module.scss";
import { useAccount, useEnsName, useEnsAvatar } from "wagmi";
import { normalize } from "viem/ens";
import makeBlockie from "ethereum-blockies-base64";
import { ZERO_ADDRESS } from "../../constants";
import { useLocalStorage, useDebounce } from "@uidotdev/usehooks";
import { getReadTime } from "./lib";

const simpleSandpackConfig = {
  defaultPreset: "react",
  presets: [
    {
      label: "React",
      name: "react",
      meta: "live react",
      sandpackTemplate: "react",
      sandpackTheme: "light",
      snippetFileName: "/App.js",
      snippetLanguage: "jsx",
      initialSnippetContent: "code",
    },
  ],
};

const Upload = () => {
  const [value, setValue] = useLocalStorage("mdxContent", "");
  const [debouncedValue] = useDebounce(value, 1000);
  const [updatedAt, setUpdatedAt] = useLocalStorage(
    "mdxContentUpdatedAt",
    new Date().toISOString(),
  );

  const editorRef = useRef(null);

  const { address } = useAccount();
  const { data: ensName, isFetched: isNameResolved } = useEnsName({
    address: address,
  });
  const { data: ensAvatar, isFetched: isAvatarResolved } = useEnsAvatar({
    name: normalize(ensName),
  });

  const safeAddress = address ? address : ZERO_ADDRESS;

  useEffect(() => {
    setUpdatedAt(new Date().toISOString());
  }, [debouncedValue]);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        {isAvatarResolved && ensAvatar ? (
          <img
            src={ensAvatar}
            alt="avatar"
            className={styles.pfp}
            onError={(e) => {
              e.target.src = makeBlockie(safeAddress);
            }}
          />
        ) : (
          <img
            src={makeBlockie(safeAddress)}
            alt="avatar"
            className={styles.pfp}
          />
        )}
        <div className={styles.col}>
          {isNameResolved && ensName ? (
            <b>{ensName}</b>
          ) : (
            <b>
              {safeAddress.slice(0, 10)}...{safeAddress.slice(-6)}
            </b>
          )}
          <span>
            Saved{" "}
            {moment(updatedAt).isSameOrAfter(moment().subtract(1, "day"))
              ? moment(updatedAt).fromNow()
              : moment(updatedAt).format("MMM D, YYYY")}{" "}
            · {getReadTime(value || "")} min read
          </span>
        </div>
      </div>

      <div
        className={styles.editorRoot}
        onClick={() => editorRef.current.focus()}
      >
        <MDXEditor
          markdown={value}
          onChange={(value) => setValue(value)}
          ref={editorRef}
          placeholder="# Write something..."
          plugins={[
            headingsPlugin(),
            quotePlugin(),
            listsPlugin(),
            thematicBreakPlugin(),
            linkPlugin(),
            linkDialogPlugin(),
            tablePlugin(),
            codeBlockPlugin({ defaultCodeBlockLanguage: "js" }),
            sandpackPlugin({ sandpackConfig: simpleSandpackConfig }),
            codeMirrorPlugin({
              codeBlockLanguages: { js: "JavaScript", css: "CSS" },
            }),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  {" "}
                  <UndoRedo />
                  <BoldItalicUnderlineToggles />
                </>
              ),
            }),

            markdownShortcutPlugin(),
          ]}
        />
      </div>
    </div>
  );
};

export default Upload;
