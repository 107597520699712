import styles from "./Footer.module.scss";
import { IoGitBranch } from "react-icons/io5";
import { Link } from "react-router-dom";

const Footer = () => {
  const gitHash = process.env.REACT_APP_GIT_HASH;

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footer}>
        <div className={styles.git}>
          <IoGitBranch className={styles.gitIcon} />
          <a
            href={`https://github.com/jon-becker/based-md/commit/${gitHash}`}
            target="_blank"
            rel="noreferrer"
          >
            {gitHash}
          </a>
        </div>
        <Link href="/about">About</Link>
      </div>
    </div>
  );
};

export default Footer;
