import { Outlet, Link } from "react-router-dom";
import styles from "./styles.module.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Layout = () => {
  return (
    <main>
      <Header />

      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>

      <Footer />
    </main>
  );
};

export default Layout;
