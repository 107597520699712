import { useBlockNumber, useClient } from "wagmi";
import Glitch from "../../components/Glitch";
import { useWatchPendingTransactions } from "wagmi";
import { useState } from "react";
import { getTransaction } from "viem/actions";

const Main = () => {
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const [transactions, setTransactions] = useState([]);
  const client = useClient();
  function numberToWords(num) {
    if (num === 0n) return "Zero";

    const LESS_THAN_20 = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const TENS = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const THOUSANDS = [
      "",
      "Thousand",
      "Million",
      "Billion",
      "Trillion",
      "Quadrillion",
      "Quintillion",
    ];

    function helper(n) {
      if (n < 20n) return LESS_THAN_20[Number(n)];
      if (n < 100n)
        return TENS[Number(n / 10n)] + (n % 10n ? " " + helper(n % 10n) : "");
      if (n < 1000n)
        return (
          LESS_THAN_20[Number(n / 100n)] +
          " Hundred" +
          (n % 100n ? " " + helper(n % 100n) : "")
        );
      for (let i = THOUSANDS.length - 1; i >= 0; i--) {
        const unit = 1000n ** BigInt(i);
        if (n >= unit) {
          return (
            helper(n / unit) +
            " " +
            THOUSANDS[i] +
            (n % unit ? ", " + helper(n % unit) : "")
          );
        }
      }
    }

    return helper(num);
  }

  useWatchPendingTransactions({
    onTransactions(transactions) {
      for (const tx of transactions) {
        console.log(tx);
        getTransaction(client, { hash: tx })
          .then((res) => {
            let tx_with_details = {
              hash: res.hash,
              input: res.input,
              from: res.from,
              to: res.to,
              value: res.value ? res.value.toString() : "0",
            };

            // skip if already in the list
            if (transactions.find((t) => t.hash === tx_with_details.hash))
              return;

            setTransactions((prev) => [...prev, tx_with_details]);
          })
          .catch((err) => {});
      }
    },
  });

  return (
    <>
      <h1>{numberToWords(blockNumber || 0)}</h1>
      <div>
        {transactions.map((tx) => (
          <pre key={tx.hash}>{JSON.stringify(tx, undefined, 2)}</pre>
        ))}
      </div>
    </>
  );
};

export default Main;
